import configData from "../appsettings.json";
import { getWebsiteInfo } from "./Utils.js";
export const getCDNLogo = function () {
    const websiteInfo = getWebsiteInfo();
    if (websiteInfo && websiteInfo.logoUrl)
        return websiteInfo.logoUrl;

    return configData.Settings.CDN_Custom + "/images/logo.png";
};

export const getCDNLogoWhite = function () {
    return configData.Settings.CDN_Custom + "/images/logo_white.png";
};

export const getCDNLogoDocument = function () {
    return configData.Settings.CDN_Custom + "/images/logo-document.png";
};

export const getCDNLogoDocumentWhite = function () {
    return configData.Settings.CDN_Custom + "/images/logo-document.png";
};

export const imageOnError = function (event, productType = null) {
    if (!event || !event.currentTarget)
        return "";

    let url = "/images/noimage/no-image-hotel.jpg";
    if (productType) {

        switch (productType) {

            case configData.Settings.Products.Activity.IdTipoProdotto: {
                url = "/images/noimage/no-image-activity.jpg";
                break;
            }

            case configData.Settings.Products.CarRental.IdTipoProdotto: {
                url = "/images/noimage/no-image-car-rental.jpg";
                break;
            }

            case configData.Settings.Products.Flight.IdTipoProdotto: {
                url = "/images/noimage/no-image-flight.jpg";
                break;
            }

            case configData.Settings.Products.Structure.IdTipoProdotto: {
                url = "/images/noimage/no-image-hotel.jpg";
                break;
            }

            case configData.Settings.Products.Transfer.IdTipoProdotto: {
                url = "/images/noimage/no-image-transfer.jpg";
                break;
            }

            case configData.Settings.Products.Train.IdTipoProdotto: {
                url = "/images/noimage/no-image-train.jpg";
                break;
            }

            case configData.Settings.Products.Cruise.IdTipoProdotto: {
                url = "/images/noimage/no-image-cruise.jpg";
                break;
            }

            case configData.Settings.Products.Generic.IdTipoProdotto: {
                url = "/images/noimage/no-image-generic.jpg";
                break;
            }

            default:
                break;
        }

    }

    event.currentTarget.src = configData.Settings.CDN_Custom + url;
};

export const imageOnLoad = function (productType = null) {
    let url = "/images/noimage/no-image-hotel.jpg";
    if (productType) {

        switch (productType) {

            case configData.Settings.Products.Activity.IdTipoProdotto: {
                url = "/images/noimage/no-image-activity.jpg";
                break;
            }

            case configData.Settings.Products.CarRental.IdTipoProdotto: {
                url = "/images/noimage/no-image-car-rental.jpg";
                break;
            }

            case configData.Settings.Products.Flight.IdTipoProdotto: {
                url = "/images/noimage/no-image-flight.jpg";
                break;
            }

            case configData.Settings.Products.Structure.IdTipoProdotto: {
                url = "/images/noimage/no-image-hotel.jpg";
                break;
            }

            case configData.Settings.Products.Transfer.IdTipoProdotto: {
                url = "/images/noimage/no-image-transfer.jpg";
                break;
            }

            case configData.Settings.Products.Train.IdTipoProdotto: {
                url = "/images/noimage/no-image-train.jpg";
                break;
            }

            case configData.Settings.Products.Cruise.IdTipoProdotto: {
                url = "/images/noimage/no-image-cruise.jpg";
                break;
            }

            case configData.Settings.Products.Generic.IdTipoProdotto: {
                url = "/images/noimage/no-image-generic.jpg";
                break;
            }

            default:
                break;
        }

    }

    return configData.Settings.CDN_Custom +url;
};


export const imageOnLoadTemplateStep = function () {
    return configData.Settings.CDN_Custom + "/images/manualloading/manual-loading-hotel.jpg";
};


export const imageOnLoadFlight = function () {
    return configData.Settings.CDN_Common + "/images/flight/flight_print.jpg";
};

export const imageOnLoadHotel = function () {
    return configData.Settings.CDN_Common + "/images/hotel/hotel_print.jpg";
};

export const imageOnLoadTransfer = function () {
    return configData.Settings.CDN_Custom + "/images/transfer/transfer.png";
};

export const imageOnLoadCruise = function () {
    return configData.Settings.CDN_Custom + "/images/manualloading/manual-loading-cruise.jpg";
};

export const imageOnLoadCarRental = function () {
    return configData.Settings.CDN_Common + "/images/manualloading/manual-loading-car-rental.jpg";
};


export const imageEnginePage = function () {
    return configData.Settings.CDN_Custom + "/images/banner1348x450.png";
};

export const imageProductPage = function () {
    return configData.Settings.CDN_Custom + "/images/banner1348x450.png";
};


export const imageOnLoadIsNotFavourite = function () {
    return configData.Settings.CDN_Common + "/images/button/is_not_favourite.png";
};

export const imageOnLoadIsFavourite = function () {
    return configData.Settings.CDN_Common + "/images/button/is_favourite.png";
};
import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { TemplateStepProduct } from "./TemplateStepProduct";
import { TemplateInclusion } from '../TemplateUtils';
import { formatDateTime } from '../../../js/Utils';

export const TemplateSteps = ({ template, steps, searchParameters, selectedProducts, setSelectedProducts }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const [templateDatas, setTemplateData] = useState([]);


    useEffect(() => {
        let templateDatas = [];

        for (let i = 0; i < steps.length; i++) {
            if (steps[i].templateData && steps[i].templateData.length > 0) {
                for (let j = 0; j < steps[i].templateData.length; j++) {
                    templateDatas.push(steps[i].templateData[j]);
                }
            }
        }

        setTemplateData(templateDatas);
    }, [steps]);

    function getStepStartDate(stepId) {
        let step = steps.filter(x => x.step === stepId)[0];
        let minDateRange = 0;

        for (let i = 0; i < step.templateData.length; i++) {
            if (step.templateData[i].daysRange && step.templateData[i].daysRange.length > 0) {
                let templateDataMinDayRange = step.templateData[i].daysRange[0];
                if (minDateRange === 0) {
                    minDateRange = templateDataMinDayRange;
                } else {
                    if (templateDataMinDayRange < minDateRange) minDateRange = templateDataMinDayRange;
                }
            }
        }

        minDateRange = minDateRange - 1; // num days to sum to checkIn

        let checkInStep = searchParameters.checkIn;
        let newDate = new Date(checkInStep);
        newDate = new Date(newDate.setDate(newDate.getDate() + minDateRange));
        checkInStep = formatDateTime(newDate, cultureName, { noYear: true });

        return checkInStep;
    }
    function getStepEndDate(stepId) {
        let step = steps.filter(x => x.step === stepId)[0];
        let maxDateRange = 0;

        for (let i = 0; i < step.templateData.length; i++) {
            if (step.templateData[i].daysRange && step.templateData[i].daysRange.length > 0) {
                let templateDataMaxDayRange = step.templateData[i].daysRange[step.templateData[i].daysRange.length - 1];

                // tipo Tour guardo la durationTime
                if (step.templateData[i].productType === 10 && step.templateData[i].product.durationTime) {
                    maxDateRange = (step.templateData[i].product.durationTime + 1); // sono notti nei tour, gli altri prodotti sono giorni
                }

                if (maxDateRange === 0) {
                    maxDateRange = templateDataMaxDayRange;
                } else {
                    if (templateDataMaxDayRange > maxDateRange) maxDateRange = templateDataMaxDayRange;
                }
            }
        }

        maxDateRange = maxDateRange - 1; // num days to sum to checkOut

        let checkInStep = searchParameters.checkIn;
        let newDate = new Date(checkInStep);
        newDate = new Date(newDate.setDate(newDate.getDate() + maxDateRange));
        checkInStep = formatDateTime(newDate, cultureName, { noYear: true });

        return checkInStep;
    }

    const renderTemplateData = (templateDatas) => {
        // qui ho tutti i TemplateDatas di uno Step
        // li raggruppo in base al tipo prodotto e al tipo di inclusion (0 e 1 vanno singolarmente, 2 va insieme alle alternative)

        let tmpGrpTemplateDatas = [];
        for (let i = 0; i < templateDatas.length; i++) {
            if (tmpGrpTemplateDatas.length === 0 || tmpGrpTemplateDatas.findIndex(x => x.productType === templateDatas[i].productType) < 0) {
                tmpGrpTemplateDatas.push({ productType: templateDatas[i].productType, templateDatas: [templateDatas[i]] });
            } else {
                let grpIdx = tmpGrpTemplateDatas.findIndex(x => x.productType === templateDatas[i].productType);
                tmpGrpTemplateDatas[grpIdx].templateDatas.push(templateDatas[i]);
            }
        }

        let grpTemplateDatas = [];
        for (let i = 0; i < tmpGrpTemplateDatas.length; i++) {
            // at least one (tutti gli at least one di uno step finiscono nello stesso gruppo)
            let templateDatasAtLeastOne = tmpGrpTemplateDatas[i].templateDatas.filter(x => x.inclusion === TemplateInclusion.AtLeastOne);
            if (templateDatasAtLeastOne.length > 0) grpTemplateDatas.push(templateDatasAtLeastOne);

            // mandatory (per ogni optional genero un gruppo)
            let templateDatasMandatory = tmpGrpTemplateDatas[i].templateDatas.filter(x => x.inclusion === TemplateInclusion.Mandatory);
            if (templateDatasMandatory.length > 0) {
                for (let j = 0; j < templateDatasMandatory.length; j++) {
                    grpTemplateDatas.push([templateDatasMandatory[j]]);
                }
            }

            // optional (per ogni optional genero un gruppo)
            let templateDatasOptionals = tmpGrpTemplateDatas[i].templateDatas.filter(x => x.inclusion === TemplateInclusion.Optional);
            if (templateDatasOptionals.length > 0) {
                for (let j = 0; j < templateDatasOptionals.length; j++) {
                    grpTemplateDatas.push([templateDatasOptionals[j]]);
                }
            }
        }

        return (
            <>
                {grpTemplateDatas.map((grpTemplateData, index) =>
                    <React.Fragment key={index}>
                        <TemplateStepProduct template={template} products={grpTemplateData} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} searchParameters={searchParameters} />
                    </React.Fragment>
                )}
            </>
        );
    }


    return (
        <>
            {steps && steps.length > 0 && templateDatas && templateDatas.length > 0 &&
                <>
                    <div className="row">
                        {steps.map((step, key) =>
                            <div key={key} className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="card p-0 mt-4 bb-0 bg-custom">
                                    {/*Testata Step*/}
                                    <div className="w-100 pl-2 h5">
                                        <span className="h4 text-white float-start"> <span className="text-white h5">{step.step}<data m3lab="Template.step"> {t(`Template:JourneyLeg`)} </data> - </span><var>{step.webDestinationName}</var></span>
                                        <span className="h5 text-white float-end my- pt-1 pr-2"><data m3lab="Template.StartStep">Dal:</data> <var>{getStepStartDate(step.step)}</var> <data m3lab="Template.EndStep">al</data> <var>{getStepEndDate(step.step)}</var></span>
                                    </div>
                                    <div className="bg-bluesoft">
                                        {
                                            step.thumbUrl && <div className="w-100">
                                                <div style={{ backgroundImage: "url(" + encodeURI(step.thumbUrl) + ")", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", height: "220px" }}>
                                                </div>
                                            </div>
                                        }

                                        {/*Template Datas*/}
                                        {renderTemplateData(step.templateData)}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            }
        </>
    );
}
